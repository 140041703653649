import React, { useState } from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import { ScrollMenu } from "react-horizontal-scrolling-menu";

import { LeftArrow, RightArrow } from "./arrows";
import { ProjectCard } from "./card";

import Assets from "../assets/assetspath";

import "../styles/projects.css";

const projects = [

    /* DoEm */
    {
        title: "Do'Em",
        description: `Get organized and stay on top of your daily tasks with Do'Em, the versatile note-taking app for Android & iOS. Built with Flutter and Firebase, Do'Em helps you effortlessly create checklists or take notes, and save them securely in the cloud. Whether you're offline or online, you can access your work and never miss a beat. With its stunning, user-friendly interface and two theme modes, Do'Em is the perfect tool to streamline your productivity and get more done. Download now and experience the power of organized efficiency!`,
        category: "mobile",
        link: "https://play.google.com/store/apps/details?id=com.doem.doem_v2",
        img: [
            Assets.assets[1][0],
            Assets.assets[1][1],
            Assets.assets[1][2],
            Assets.assets[1][3],
            Assets.assets[1][4],
            Assets.assets[1][5],
            Assets.assets[1][6],
        ],
    },

    /* Wilayat */
    {
        title: "Wilayat",
        description: `Stay informed and up-to-date with Wilayat, the go-to app for all things Algeria. With a simple and intuitive design, access comprehensive information on the country's 58 departments (Wilayas) right from your pocket. Wilayat is your reliable source for the latest news and developments, providing in-depth coverage of each Wilaya. Say goodbye to scouring the web for information, and experience the convenience of having everything you need to know at your fingertips with Wilayat.`,
        category: "mobile",
        link: "https://play.google.com/store/apps/details?id=com.wilayat.app",
        img: [
            Assets.assets[5][0],
            Assets.assets[5][1],
            Assets.assets[5][2],
            Assets.assets[5][3],
        ],
    },

    /* BFast */
    {
        title: "BFast",
        description: `Put your reflexes to the test with BFast - the ultimate reaction game! With multiple modes to choose from, you'll never get bored. In Mode 1, wait for the screen to turn yellow and click with lightning-fast speed. Mode 2 challenges you to tap randomly appearing bees before they disappear and earn points for every successful hit. In Mode 3, follow the direction of the bee arrow with quick gestures. And in Mode 4, put your precision to the test as you identify and click on wasps while avoiding bees. With randomized appearances and time-sensitive objectives, BFast will keep you on the edge of your seat. Download now and see how high you can score!
        `,
        category: "mobile",
        link: "https://github.com/GitGud31/BFast-game",
        img: [
            Assets.assets[2][0],
            Assets.assets[2][1],
            Assets.assets[2][2],
            Assets.assets[2][3],
            Assets.assets[2][4],
            Assets.assets[2][5]
        ],
    },

    /* Hirfa */
    {
        title: "Hirfa",
        description: `Hirfa is an app that helps you find skilled handymen near you, 
        whether you're new to the neighborhood or have trouble deciding which handymen to choose, with Hirfa consider it done. 
        - Contact your nearest handyman directly on the main page. - Post an ad with your specifications and get a response from experienced handymen. 
        - If you are a handyman don't worry anymore. 
        Hirfa is here to promote you, customers will be able to contact you directly, discuss and show your skills.`,
        category: "mobile",
        link: "https://play.google.com/store/apps/details?id=hextech.gg.corp.hirfa",
        img: [
            Assets.assets[0][0],
            Assets.assets[0][1],
            Assets.assets[0][2],
            Assets.assets[0][3],
            Assets.assets[0][4],
        ],
    },

    /* GNews */
    /* {
        title: "GNews",
        description: `GNews (Global News) is a personalized news application for Android and iOS that organizes 
        and highlights what’s happening in the world so you can discover more about the stories that matter to you. 
        The app offers: Two Theme modes Dark & Light, Save your favorite Articles locally, Search by Category & Keywords, 
        all presented in a beatiful modern UI.`,
        category: "mobile",
        link: "",
        img: [
            Assets.assets[3][0],
            Assets.assets[3][1],
            Assets.assets[3][2],
            Assets.assets[3][3],
            Assets.assets[3][4],
            Assets.assets[3][5],
            Assets.assets[3][6],
            Assets.assets[3][7]
        ],
    }, 
    */

    /* Teleport */
    {
        title: "Teleport",
        description: `Teleport was my Master's degree final project. As a whole, 
        Teleport is a Cloud based system, made to control a telepresence robot remotely via a mobile App. Technologies used:
        Flutter, ROW, AWS serverless, Firebase, Agora`,
        category: "mobile",
        link: "https://github.com/GitGud31/PFE-Teleport",
        img: [
            Assets.assets[4][0],
        ],
    },

    /* Weathery */
    {
        title: "Weathery",
        description: `A simple Web app built with React, featuring: 
        - Search functionality 
        - Celsius to Fahrenheit (and vice versa) conversion 
        - Get weather by coordinates 
        - Get Today's and next week's weather info 
        - Error page.
        `,
        category: "web",
        link: "https://github.com/GitGud31/Weathery",
        img: [
            Assets.assets[6][0],
        ],
    },

    /* carte fiedelite */
    {
        title: "Carte Fidèlité",
        description: `A loyalty card design for a supermarket
        `,
        category: "design",
        link: "",
        img: [
            Assets.assets[7][0],
            Assets.assets[7][1],
            Assets.assets[7][2],
            Assets.assets[7][3],
        ],
    },

    /* Cigma */
    {
        title: "Cigma couture",
        description: "A design for a sewing shop",
        category: "design",
        link: "",
        img: [
            Assets.assets[8][0],
            Assets.assets[8][1],
            Assets.assets[8][2],
            Assets.assets[8][3],
            Assets.assets[8][4],
            Assets.assets[8][5],
            Assets.assets[8][6],
        ],
    },
];


const getItems = () =>
    projects.map((p, key) => ({
        id: key,
        title: p.title,
        category: p.category,
        link: p.link,
        description: p.description,
        img: p.img,
    }));


function Projects() {
    let [items, setItems] = useState(getItems);

    const filterHandler = (i) => {

        items = getItems();

        switch (i) {
            case 1:
                items = getItems();
                break;

            case 2:
                items = items.filter(project => (project.category === "mobile"));
                break;

            case 3:
                items = items.filter(project => (project.category === "web"));
                break;

            case 4:
                items = items.filter(project => (project.category === "design"));
                break;

            default:
                items = getItems();
                break;
        }

        setItems(items);
    }

    return (<section className="projects-section">
        <div className="projects-title">
            <h1>PROJECT<span>S</span></h1>
        </div>

        <div className="projects-content">
            <div className="filter">
                <ButtonGroup>
                    <Button onClick={() => filterHandler(1)} size="sm" className="btn-filter">all</Button>
                    <Button onClick={() => filterHandler(2)} size="sm" className="btn-filter">mobile</Button>
                    <Button onClick={() => filterHandler(3)} size="sm" className="btn-filter">web</Button>
                    <Button onClick={() => filterHandler(4)} size="sm" className="btn-filter">design</Button>
                </ButtonGroup>
            </div>

            <ScrollMenu
                wrapperClassName="slider"
                LeftArrow={LeftArrow}
                RightArrow={RightArrow}
                onWheel={onWheel}>

                {
                    items.map(function (project) {

                        return <ProjectCard
                            key={project.id}
                            itemId={project.id}
                            title={project.title}
                            description={project.description}
                            repo={project.link}
                            category={project.category}
                            imgLink={project.img}
                        />
                    })
                }
            </ScrollMenu>

        </div>

    </section>);
}

export default Projects;


function onWheel(apiObj, ev) {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
        ev.stopPropagation();
        return;
    }

    if (ev.deltaY < 0) {
        apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
        apiObj.scrollPrev();
    }
}