import React from "react";

import CvButton from "./cv-button";
import profileImg from "../assets/profile-img.png";

import "../styles/about.css";

function About() {
    return (<section className="about-section">

        <div className="about-content">

            <div className="about-title">
                <h1>About <span>me</span></h1>
            </div>

            <div className="about-main-text">
                <h6>
                My name is M'hamed Lehbab, I am a driven and passionate individual with a background in embedded systems, 
                Mobile & Web apps development.
                I have honed my skills in Flutter and React.
                I am constantly seeking new challenges and opportunities to expand my knowledge and make a positive impact in my field.

                <br/>
                <br/>
                Let's work together to achieve greatness!
                </h6>
            </div>

            <CvButton>download cv</CvButton>

        </div>

        <div className="sized-box"></div>

        <div className="profile-img">
            <img src={profileImg} alt="" />
        </div>

    </section>);
}

export default About;